import { Flex, Heading, Img, Text, VStack } from '@chakra-ui/react';

import { PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';

export const SupportSection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const { title, subtitle, description, imageUrl } = content.support;

  return (
    <PageContainer bgGradient="linear(to-r, cyan.700, blue.600)">
      <PageContent
        align="center"
        direction={['column', 'column', 'row']}
        color="white"
      >
        <VStack align="flex-start" spacing="1rem">
          <Heading fontSize="1.25rem">{title}</Heading>

          <Heading fontSize="1.5rem" color="network.secondary">
            {subtitle}
          </Heading>

          <Text fontSize="1.125rem" maxW="45rem" whiteSpace="pre-line">
            {description}
          </Text>
        </VStack>

        <Flex
          h="full"
          mt={['3rem', '3rem', '-3.5rem']}
          ml={[0, 0, '2rem']}
          justify="center"
        >
          <Img
            src={imageUrl}
            alt="Cash management"
            loading="lazy"
            w="full"
            mixBlendMode="color-dodge"
          />
        </Flex>
      </PageContent>
    </PageContainer>
  );
};
//
