import {
  Box,
  Grid,
  GridItem,
  Heading,
  Icon,
  Img,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FaPlayCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

import { Button, PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { VideoModal } from './VideoModal';

export const HowToSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { content } = usePageTextContent<NetworksPageContent>();

  const {
    title,
    subtitle,
    description,
    takeALook,
    videoThumbnailUrl,
    videoUrl,
    redeemImageUrl,
    appImageUrl,
  } = content.howTo;

  return (
    <>
      <VideoModal isOpen={isOpen} onClose={onClose} videoUrl={videoUrl} />

      <PageContainer bgGradient="linear(to-r, gray.50, gray.100)">
        <PageContent align="center" direction={['column', 'column', 'row']}>
          <VStack align="flex-start" spacing="1rem">
            <Heading fontSize="1.25rem" color="network.primary">
              {title}
            </Heading>

            <Heading fontSize="1.5rem">{subtitle}</Heading>

            <Text fontSize="1.125rem" maxW="42rem" whiteSpace="pre-line">
              {description}
            </Text>

            <Button
              variant="link"
              color="network.secondary"
              textDecor="underline"
              _hover={{ color: 'network.secondary' }}
              _active={{ color: 'network.secondary' }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={onOpen}
            >
              {takeALook}
            </Button>
          </VStack>

          <Grid
            templateColumns="repeat(2, 1fr)"
            h="full"
            mt={['3rem', '3rem', '-3.5rem']}
            ml={[0, 0, '2rem']}
            gap="2.25rem"
          >
            <GridItem>
              <VStack
                h="full"
                align="center"
                justify="center"
                spacing={['2.25rem', '2.25rem', '4rem']}
              >
                <Box
                  pos="relative"
                  role="button"
                  as={motion.button}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onOpen}
                >
                  <Img
                    src={videoThumbnailUrl}
                    alt="Video"
                    loading="lazy"
                    w="full"
                    shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
                    rounded="lg"
                  />

                  <Icon
                    as={FaPlayCircle}
                    color="network.primary"
                    fontSize="5rem"
                    pos="absolute"
                    right="50%"
                    top="50%"
                    transform="translate(50%, -50%)"
                  />
                </Box>

                <Img
                  src={redeemImageUrl}
                  alt="Redeem"
                  loading="lazy"
                  w="full"
                  shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
                  rounded="lg"
                />
              </VStack>
            </GridItem>

            <GridItem>
              <Img
                src={appImageUrl}
                alt="Redeem"
                loading="lazy"
                shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
                rounded="lg"
                w="full"
              />
            </GridItem>
          </Grid>
        </PageContent>
      </PageContainer>
    </>
  );
};
