import { Heading, Text, VStack } from '@chakra-ui/react';

import { PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';

export const SecuritySection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const { backgroundImageUrl, title, subtitle, description } = content.security;

  return (
    <PageContainer
      backgroundImage={backgroundImageUrl}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <PageContent align="center" justify="center">
        <VStack
          align="flex-start"
          spacing="1rem"
          bgGradient="linear(to-tr, blue.600, cyan.600)"
          py="3rem"
          px="2.25rem"
          rounded="xl"
          color="white"
        >
          <Heading fontSize="1.25rem">{title}</Heading>

          <Heading fontSize="1.5rem" color="network.secondary">
            {subtitle}
          </Heading>

          <Text fontSize="1.125rem" maxW="42rem" whiteSpace="pre-line">
            {description}
          </Text>
        </VStack>
      </PageContent>
    </PageContainer>
  );
};
