import { Center, Heading, Img, Text, VStack } from '@chakra-ui/react';

import { Button, Link, PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { Paths } from 'utils/paths';

export const IntroductionSection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const {
    title,
    introduction,
    description,
    registerButton,
    references,
    pictureUrl,
  } = content.introduction;

  return (
    <PageContainer bgGradient="linear(to-r, blue.600, blue.800)" isFullHeight>
      <PageContent
        align="center"
        justify="center"
        direction={['column', 'column', 'row']}
      >
        <VStack
          align={['center', 'center', 'flex-start']}
          spacing="3rem"
          w="full"
          minW="60%"
        >
          <VStack align="flex-start" spacing="1.25rem">
            <Heading color="white" fontSize="1.875rem">
              {title}
            </Heading>

            <Heading
              color="network.secondary"
              fontSize="2.5rem"
              whiteSpace="pre-line"
            >
              {introduction}
            </Heading>
          </VStack>

          <Text
            color="white"
            fontSize="1.125rem"
            maxW="35rem"
            whiteSpace="pre-line"
          >
            {description}
          </Text>

          <Link href={Paths.REGISTER_NETWORK}>
            <Button
              variant="network"
              borderWidth="2px"
              borderStyle="solid"
              borderColor="white"
            >
              {registerButton}
            </Button>
          </Link>

          <Link
            href={Paths.NETWORK_PRICING_CALCULATOR}
            color="network.secondary"
            textDecor="underline"
            _hover={{ color: 'network.secondary' }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {references}
          </Link>
        </VStack>

        <Center
          w="full"
          maxWidth={['25rem', '25rem', '32rem']}
          my={['2rem', '2rem', 0]}
          display={['none', 'none', 'flex']}
          visibility={['hidden', 'hidden', 'visible']}
        >
          <Img src={pictureUrl} w="full" alt="Networks Icon" loading="lazy" />
        </Center>
      </PageContent>
    </PageContainer>
  );
};
