import { Flex, Heading, Img, Text, VStack } from '@chakra-ui/react';

import { Link, PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { Paths } from 'utils/paths';

export const CashManagementSection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const { title, subtitle, description, howToManage, imageUrl } =
    content.cashManagement;

  return (
    <PageContainer bgGradient="linear(to-r, cyan.700, blue.600)">
      <PageContent
        align="center"
        direction={['column', 'column', 'row']}
        color="white"
      >
        <VStack align="flex-start" spacing="1rem">
          <Heading fontSize="1.25rem">{title}</Heading>

          <Heading fontSize="1.5rem" color="network.secondary">
            {subtitle}
          </Heading>

          <Text fontSize="1.125rem" maxW="45rem" whiteSpace="pre-line">
            {description}
          </Text>

          <Link
            href={Paths.NETWORK_CASH_MANAGEMENT}
            color="network.secondary"
            textDecor="underline"
            _hover={{ color: 'network.secondary' }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {howToManage}
          </Link>
        </VStack>

        <Flex
          h="full"
          mt={['3rem', '3rem', '-3.5rem']}
          ml={[0, 0, '2rem']}
          justify="center"
        >
          <Img
            src={imageUrl}
            alt="Cash management"
            loading="lazy"
            w="full"
            mixBlendMode="color-dodge"
          />
        </Flex>
      </PageContent>
    </PageContainer>
  );
};
//
