import { Flex, Heading, Img, Text, VStack } from '@chakra-ui/react';

import { Link, PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { Paths } from 'utils/paths';

export const LegalSection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const { imageUrl, title, subtitle, description, legalBases } = content.legal;

  return (
    <PageContainer>
      <PageContent direction={['column', 'column', 'row']}>
        <Flex mt={['3rem', '3rem', '-3.5rem']} align="center" justify="center">
          <Img
            src={imageUrl}
            alt="Portal"
            loading="lazy"
            w="full"
            shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
            rounded="md"
          />
        </Flex>

        <VStack
          align="flex-start"
          spacing="1rem"
          ml={[0, 0, '3rem']}
          mt={['3rem', '3rem', 0]}
        >
          <Heading fontSize="1.25rem">{title}</Heading>

          <Heading fontSize="1.5rem" color="network.secondary">
            {subtitle}
          </Heading>

          <Text fontSize="1.125rem" maxW="42rem" whiteSpace="pre-line">
            {description}
          </Text>

          <Link
            href={Paths.NETWORK_LEGAL}
            color="network.secondary"
            textDecor="underline"
            _hover={{ color: 'network.secondary' }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {legalBases}
          </Link>
        </VStack>
      </PageContent>
    </PageContainer>
  );
};
