import {
  AspectRatio,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  chakra,
} from '@chakra-ui/react';
import { Button } from 'components';

import { usePageTextContent } from 'context/pageTextContent';
import { useZmyleCookieStore } from 'hooks/useZmyleCookieStore';
import { CookieWidget } from 'services/cookieWidget';

interface Props {
  isOpen: boolean;
  onClose(): void;
  videoUrl: string;
}

export const VideoModal = (props: Props) => {
  const { isOpen, onClose, videoUrl } = props;

  const { content } = usePageTextContent<NetworksPageContent>();

  const zmyleCookie = useZmyleCookieStore(state => state.zmyleCookie);

  const isYoutubeCookieEnabled = zmyleCookie.consents.media.includes('youtube');

  const onOpenMediaCookie = () => {
    onClose();

    const cookieWidget = CookieWidget.getInstance();

    cookieWidget.open({ section: 'media' });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent w="full" rounded="xl">
        <ModalBody p={0}>
          {!isYoutubeCookieEnabled && (
            <AspectRatio ratio={16 / 9}>
              <Center py="2.5rem">
                <Button
                  variant="unstyled"
                  textAlign="center"
                  onClick={onOpenMediaCookie}
                  maxW="22.5rem"
                  whiteSpace="pre-line"
                >
                  {content.howTo.youtubeVideoCookieInstruction}
                </Button>
              </Center>
            </AspectRatio>
          )}

          {isYoutubeCookieEnabled && (
            <AspectRatio ratio={16 / 9}>
              <chakra.iframe
                src={videoUrl}
                title="YouTube video player"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                rounded="xl"
              />
            </AspectRatio>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
