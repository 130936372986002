import {
  Box,
  Flex,
  Heading,
  Icon,
  Img,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaPlayCircle } from 'react-icons/fa';

import { PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { VideoModal } from './VideoModal';

export const MarketingSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { content } = usePageTextContent<NetworksPageContent>();

  const {
    title,
    subtitle,
    description,
    imageUrl,
    videoThumbnailUrl,
    videoUrl,
  } = content.marketing;

  return (
    <>
      <VideoModal isOpen={isOpen} onClose={onClose} videoUrl={videoUrl} />

      <PageContainer>
        <PageContent align="center" direction={['column', 'column', 'row']}>
          <VStack align="flex-start" spacing="1rem">
            <Heading fontSize="1.25rem" color="network.primary">
              {title}
            </Heading>

            <Heading fontSize="1.5rem">{subtitle}</Heading>

            <Text fontSize="1.125rem" maxW="45rem">
              {description}
            </Text>
          </VStack>

          <Flex
            h="full"
            mt={['3rem', '3rem', '-3.5rem']}
            ml={[0, 0, '2rem']}
            pos="relative"
          >
            <Img
              src={imageUrl}
              alt="Overlay"
              loading="lazy"
              w="full"
              rounded="md"
            />

            <Box
              pos="absolute"
              right="50%"
              bottom="50%"
              transform="translate(50%, 50%)"
            >
              <Box
                pos="relative"
                role="button"
                as={motion.button}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={onOpen}
              >
                <Img
                  src={videoThumbnailUrl}
                  rounded="md"
                  alt="Video Thumbnail"
                  loading="lazy"
                />
                <Icon
                  as={FaPlayCircle}
                  color="network.primary"
                  fontSize="5rem"
                  pos="absolute"
                  right="50%"
                  top="50%"
                  transform="translate(50%, -50%)"
                />
              </Box>
            </Box>
          </Flex>
        </PageContent>
      </PageContainer>
    </>
  );
};
