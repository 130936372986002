import { Flex, Heading, Img, Text, VStack } from '@chakra-ui/react';

import { Link, PageContainer, PageContent } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { Paths } from 'utils/paths';

export const FeaturesSection = () => {
  const { content } = usePageTextContent<NetworksPageContent>();

  const {
    title,
    subtitle,
    description,
    knowFeatures,
    portalImageUrl,
    backofficeImageUrl,
  } = content.features;

  return (
    <PageContainer>
      <PageContent align="center" direction={['column', 'column', 'row']}>
        <VStack align="flex-start" spacing="1rem">
          <Heading fontSize="1.25rem" color="network.primary">
            {title}
          </Heading>

          <Heading fontSize="1.5rem">{subtitle}</Heading>

          <Text fontSize="1.125rem" maxW="42rem">
            {description}
          </Text>

          <Link
            href={Paths.NETWORK_FEATURES}
            color="network.secondary"
            textDecor="underline"
            _hover={{ color: 'network.secondary' }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {knowFeatures}
          </Link>
        </VStack>

        <Flex
          h="full"
          mt={['3rem', '3rem', '-3.5rem']}
          ml={[0, 0, '2rem']}
          mr={[0, 0, '4rem']}
          pos="relative"
          justify="center"
          py="6rem"
        >
          <Img
            src={portalImageUrl}
            alt="Portal"
            loading="lazy"
            w="full"
            shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
            rounded="md"
          />

          <Img
            src={backofficeImageUrl}
            shadow="0 1px 5px 2px rgba(0,0,0,0.16);"
            rounded="md"
            alt="Backoffice"
            loading="lazy"
            pos="absolute"
            w="full"
            bottom={['3rem', '3rem', '1.5rem']}
            right={['-1.5rem', '-1.5rem', '-3rem']}
          />
        </Flex>
      </PageContent>
    </PageContainer>
  );
};
