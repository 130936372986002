'use client';

import { VStack } from '@chakra-ui/react';

import { CashManagementSection } from './components/CashManagementSection';
import { FeaturesSection } from './components/FeaturesSection';
import { HowToSection } from './components/HowToSection';
import { IntroductionSection } from './components/IntroductionSection';
import { LegalSection } from './components/LegalSection';
import { MarketingSection } from './components/MarketingSection';
import { SecuritySection } from './components/SecuritySection';
import { SupportSection } from './components/SupportSection';

interface Props {
  content: NetworksPageContent;
}

export type NetworksPageProps = Props;

const NetworksPage = () => {
  return (
    <VStack width="full" spacing={0}>
      <IntroductionSection />

      <FeaturesSection />

      <HowToSection />

      <CashManagementSection />

      <LegalSection />

      <SecuritySection />

      <MarketingSection />

      <SupportSection />
    </VStack>
  );
};

export default NetworksPage;
